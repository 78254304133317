<template>
  <v-content>
    <div class="page">
      <div class="imgweb d-none d-sm-none d-md-block mt-0 pt-0">
        <AppBar :drawer="false"/>
        <v-progress-linear
          v-if="carregando"
          indeterminate
          color="#70445E"
        ></v-progress-linear>
        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="container" id="topoBlog">
                <span class="text-h4 laubeFont" style="display:flex; justify-content: center;color: #70445E;">Blog</span>
                <v-row class="align-start mt-5">
                  <v-col cols="12">
                    <v-text-field
                      v-model="search"
                      dense
                      prepend-icon="mdi-magnify"
                      placeholder="Pesquise o post..."
                      color="#70445E"
                      clearable
                    >
                    </v-text-field>
                  </v-col>
                  <v-col
                    cols="6"
                    v-for="(post, i) in blogPosts"
                    :key="i"
                  >
                  <v-card
                    style="background-color: transparent;"
                    class="pa-2 elevation-1"
                    @click="verPost(post)"
                  >
                    <span class="title laubeFont" style="color: #70445E">{{ post.title }}</span><br />
                    <v-img :src="post.imageUrl" contain width="200" height="200" style="margin: 0 auto;"></v-img>
                    <span class="caption laubeFont">por Daniela Laubé</span><br />
                    <span class="caption laubeFont">em {{ new Date(post.createdAt).toLocaleString() }}</span>
                  </v-card>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div class="imgmobi d-md-none d-lg-none d-xl-none pt-0 mt-0" style="overflow-y: auto !important;" v-scroll.self="onScroll">
        <AppBar :drawer="false"/>
        <v-progress-linear
          v-if="carregando"
          indeterminate
          color="#70445E"
        ></v-progress-linear>
        <div class="container" id="topoBlog" style="margin-bottom: 20vh;">
          <span class="text-h4 laubeFont" style="display:flex; justify-content: center;color: #70445E;">Blog</span>
          <v-row class="align-start mt-5">
            <v-col cols="12">
              <v-text-field
                v-model="search"
                dense
                prepend-icon="mdi-magnify"
                placeholder="Pesquise o post..."
                color="#70445E"
                clearable
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="6"
              v-for="(post, i) in blogPosts"
              :key="i"
            >
            <v-card
              style="background-color: transparent;"
              class="pa-2 elevation-1"
              @click="verPost(post)"
            >
              <span class="title laubeFont" style="color: #70445E">{{ post.title }}</span><br />
              <v-img :src="post.imageUrl" contain width="200" height="200" style="margin: 0 auto;"></v-img>
              <span class="caption laubeFont">por Daniela Laubé</span><br />
              <span class="caption laubeFont">em {{ new Date(post.createdAt).toLocaleString() }}</span>
            </v-card>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </v-content>
</template>

<script>
// import $ from 'jquery'
import axios from 'axios'
import AppBar from '../components/AppBar.vue'
export default {
  name: 'ListaBlog',
  components: {
    AppBar
  },
  data () {
    return {
      carregando: false,
      search: '',
      blogPosts: [],
      originalPosts: [],
      buscando: false,
    }
  },
  mounted () {
    this.getLista()
  },
  watch: {
    search: function(newVal){
      if (newVal) {
        this.buscando = true
        this.carregando = true
        this.buscar(newVal)
      } else {
        this.getLista()
      }
    },
  },
  methods: {
    async getLista () {
      this.carregando = true
      axios.get('https://laubeapi.azurewebsites.net/api/Blog/')
      // axios.get('https://localhost:44303/api/Blog/')
      .then((r) => {
        this.blogPosts = r.data
        this.carregando = false
      })
      .catch((e) => {
        console.log(e)
      })
    },
    buscar (post) {
      this.originalPosts = this.blogPosts
      this.blogPosts = this.blogPosts.filter(b => b.title.toLowerCase().startsWith(post.toLowerCase()))
      this.carregando = false
    },
    verPost (post) {
      this.$router.push({path: '/blog/' + post.id});
    },
    gotoExt () {
      window.open(
        this.url,
        '_blank'
      );
    },
    gotoInt () {
      window.location.href = this.dirr
    },
    gotoPage (link) {
      var target = link === this.url ? '_blank' : '_self'
      window.open(
        link,
        target
      );
    },
    onScroll () {
      this.drawer ? this.drawer = false : this.drawer = true
    },
  },
}
</script>
<style scoped>
    @font-face {
      font-family: Letra;
      src: url('../assets/fonts/OpenSans-VariableFont_wdth,wght.ttf');
    }
    @font-face {
      font-family: Laube;
      src: url('../assets/fonts/Blue Vinyl Fonts - BlueVinyl-Bold.otf');
    }
    .laubeFont {
      font-family: Letra !important;
      font-weight: bolder !important;
    }
    .imgweb {
      background-image: url('../assets/img/fundo2web.png');
      background-color: #fdede6;
      background-size: cover;
      background-position-y: -100px;
      background-attachment: fixed;
      min-height: 100vh;
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
    }
    .imgmobi {
      background-image: url('../assets/img/fundocel2.png');
      background-color: #d4c1ba;
      background-position: top;
      background-size: cover;
      height: 100vh;
      background-repeat: no-repeat;
    }
    .linkGaleria {
      display: flex;
      flex-direction:row-reverse;
      text-decoration: none;
      color:#70445E;
    }
</style>
